import Link from "next/link"
import { NavLink } from "../components/NavLink"
import useT from "../utils/useT"

const Page404 = () => {
  const t = useT()
  return (
    <div className="card card-style text-center">
      <div className="content pt-4 pb-4">
        <h1>
          <i className="fa fa-exclamation-triangle color-red-dark fa-5x"></i>
        </h1>
        <h1 className="fa-6x pt-5 pb-3">404</h1>
        <h3 className="text-uppercase pb-3">{t.page404.pageNotFound}</h3>
        <p className="boxed-text-l">{t.page404.pageNotFoundSub}</p>
        <div className="row mb-0 mt-5">
          <div className="col-6 pe-0">
            <Link
              href={"/"}
              className="back-button btn btn-m rounded-s btn-center-m bg-highlight font-800 text-uppercase"
            >
              {t.page404.goHome}
            </Link>
          </div>
          <div className="col-6 ps-0">
            <NavLink
              href="/contact"
              className="back-button btn btn-m rounded-s btn-center-m bg-highlight font-800 text-uppercase"
            >
              {t.page404.contact}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404
